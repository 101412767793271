<!--
 * @Description: 任务项商品信息弹框
 * @Author: LiangYiNing
 * @Date: 2022-02-16 14:17:58
 * @LastEditTime: 2022-07-11 10:08:28
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    title="商品信息"
    :visible.sync="dialogVisible"
    width="900px"
    :before-close="handleClose"
    :close-on-click-modal="false"
    v-loading="loading"
    :element-loading-background="loadingBackground"
    custom-class="edit-job-dialog"
  >
    <el-table border height="400px" :data="tableData" highlight-current-row>
      <el-table-column
        label="序号"
        width="50"
        fixed="left"
        header-align="center"
        align="center"
      >
        <template slot-scope="scope">
          <span>
            {{ scope.$index + 1 }}
          </span>
        </template>
      </el-table-column>
      <!-- 序号 end -->
      <el-table-column
        show-overflow-tooltip
        v-for="(column, index) in columnData"
        :key="index"
        :prop="column.fieldName"
        :label="column.fieldLabel"
        :min-width="column.width"
        :fixed="column.fixed"
        :align="column.align"
        header-align="center"
      >
        <template slot-scope="{ row }">
          <span v-if="column.fieldName === 'commodity'">
            <el-button type="text" @click="openGoodsInfo(row)">
              {{ row[column.fieldName] }}
            </el-button>
          </span>
          <span v-else>{{ row[column.fieldName] }}</span>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>
<script>
import record from "../mixins";
import { printError } from "@/utils/util";
import { getTaskItemProductList } from "@/api";
export default {
  name: "",
  mixins: [record],
  data() {
    return {
      dialogVisible: true,
      loading: false,
      headerType: 22,
      tableData: []
    };
  },
  computed: {
    dynamicRules() {
      let editFormRules = {};
      return editFormRules;
    }
  },
  methods: {
    init(row) {
      this.dialogVisible = true;
      this.queryList(row);
    },
    handleClose() {
      this.dialogVisible = false;
    },

    //获取列表数据
    async queryList(data = {}) {
      let { requestId, itemId } = data;
      if (!requestId || !itemId) {
        return;
      }
      try {
        this.loading = true;
        let res = await getTaskItemProductList(data);
        this.tableData = this.getFreezeResponse(res, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .edit-job-dialog {
  .el-dialog__body {
    padding: 20px 60px 2px 20px;
  }
}
</style>
