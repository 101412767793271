import { render, staticRenderFns } from "./taskActiveGoodsInfo.vue?vue&type=template&id=68f77455&scoped=true"
import script from "./taskActiveGoodsInfo.vue?vue&type=script&lang=js"
export * from "./taskActiveGoodsInfo.vue?vue&type=script&lang=js"
import style0 from "./taskActiveGoodsInfo.vue?vue&type=style&index=0&id=68f77455&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68f77455",
  null
  
)

export default component.exports