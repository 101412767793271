<!--
 * @Description: 任务项日志
 * @Author: LiangYiNing
 * @Date: 2022-02-16 10:21:05
 * @LastEditTime: 2022-09-22 08:51:06
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="headerType">
              <e6-vr-select
                v-model="headerType"
                :data="recordTypeList"
                placeholder="日志类型"
                title="日志类型"
                @change="handleChange(headerType)"
                :is-title="true"
              ></e6-vr-select>
            </el-form-item>

            <el-form-item class="search-item--1" prop="workNo">
              <el-input
                v-model="searchForm.workNo"
                placeholder="所属任务单号"
                title="所属任务单号"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="itemId">
              <el-input
                v-model="searchForm.itemId"
                placeholder="工单ID"
                title="工单ID"
                maxlength="10"
                @input="
                  searchForm.itemId = searchForm.itemId.replace(/[^0-9-]+/, '')
                "
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="sceneName">
              <el-input
                v-model="searchForm.sceneName"
                placeholder="场景名称"
                title="场景名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="operator">
              <el-input
                v-model="searchForm.operator"
                placeholder="操作人"
                title="操作人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--2" prop="operationTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="操作时间"
                ref="effectiveDatePicker"
                v-model="searchForm.operationTime"
                title="操作时间"
                :picker-options="pickerOptions('searchForm.operationTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="操作时间（始）"
                end-placeholder="操作时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <!-- <i class="e6-icon-history" title="历史记录"></i> -->
          <el-button
            type="primary"
            @click="handleContrast"
            style="margin-right:10px"
            >对比信息</el-button
          >
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          ref="tableList"
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          @selection-change="handleSelectionChange"
          :header-cell-class-name="cellClass"
          class="table"
        >
          <el-table-column
            type="selection"
            width="50"
            :selectable="checkSelectable"
          ></el-table-column>
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="column.fieldName === 'commodity'">
                <el-button type="text" @click="openGoodsInfo(row)">
                  商品信息
                </el-button>
              </span>
              <span v-else>{{ row[column.fieldName] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <!--   -->
    <Contrast
      :dialogVisible="dialogVisible"
      :data="contrastData"
      :columnTitle="columnTitle"
      :rowData="rowData"
      @handleClose="handleClose"
    >
    </Contrast>
    <taskActiveGoodsInfo
      ref="taskActiveGoodsInfo"
      v-if="taskActiveGoodsInfoVisible"
    ></taskActiveGoodsInfo>
  </div>
</template>

<script>
// import { printError } from "@/utils/util";
import taskActiveGoodsInfo from "./taskActiveGoodsInfo";
import { getTaskItemHisList, getTaskItemProductContrast } from "@/api";
import record from "../mixins";
export default {
  name: "taskOrderActiveRecord",
  mixins: [record],
  components: { taskActiveGoodsInfo },
  data() {
    return {
      searchForm: {
        workType: 1,
        workNo: "", // 所属任务单号
        itemId: "", // 工单ID
        sceneName: "", // 场景名称
        operator: "",
        operationTime: [],
        operationStartTime: "",
        operationEndTime: "",
        pageIndex: 1,
        pageSize: 20
      },
      taskActiveGoodsInfoVisible: false,
      dataIdFixedName: "hisId", // 配置数据唯一标识 字段名
      queryListAPI: getTaskItemHisList, //获取列表的请求名
      recordTypeList: [
        {
          id: 23,
          label: "新装任务项"
        },
        {
          id: 24,
          label: "加装任务项"
        },
        {
          id: 19,
          label: "重装任务项"
        },
        {
          id: 18,
          label: "巡检任务项"
        },
        {
          id: 20,
          label: "拆卸任务项"
        },
        {
          id: 21,
          label: "以新换旧任务项"
        },
        {
          id: 25,
          label: "维修任务项"
        }
      ],
      headerType: 23
    };
  },
  watch: {
    // 创建开始时间
    "searchForm.operationTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.operationTime = this.searchForm.operationTime.reverse();
        }
        this.searchForm.operationStartTime = val[0] || "";
        this.searchForm.operationEndTime = val[1] || "";
      }
    }
  },
  computed: {},
  methods: {
    openGoodsInfo(row) {
      this.taskActiveGoodsInfoVisible = true;
      this.$nextTick(() => {
        this.$refs.taskActiveGoodsInfo.init(row);
      });
    },
    //处理对比(重写一下除了特殊字段数据)
    handleContrast() {
      if (this.selectColumn.length < 2) {
        this.$message.warning("请选择2条数据进行对比");
        return;
      }
      this.contrastData = JSON.parse(JSON.stringify(this.selectColumn));
      this.dialogVisible = true;
      this.getTaskItemProductContrast();
    },
    async getTaskItemProductContrast() {
      let queryMap = {};
      let queryList = this.contrastData.map(item => {
        queryMap[item.itemId + item.requestId] = item;
        return {
          itemId: item.itemId,
          requestId: item.requestId
        };
      });
      try {
        let res = await getTaskItemProductContrast(queryList);
        if (res.code === "OK") {
          res.data.map(item => {
            this.$set(
              queryMap[item.itemId + item.requestId + ""],
              "commodity",
              item.productStr
            );
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleChange(val) {
      switch (val) {
        case 18:
          this.searchForm.workType = 11;
          break;
        case 19:
          this.searchForm.workType = 3;
          break;
        case 20:
          this.searchForm.workType = 12;
          break;
        case 21:
          this.searchForm.workType = 7;
          break;
        case 23:
          this.searchForm.workType = 1;
          break;
        case 24:
          this.searchForm.workType = 2;
          break;
        case 25:
          this.searchForm.workType = 10;
          break;
      }
      this.searchForm.pageIndex = 1;
      this.tableData = [];
      this.getHeadersReq();
      this.getCompareHeaderReq();
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.table {
  .DisableSelection {
    .cell {
      display: none !important;
    }
  }
}
</style>
